import React from "react";
import loadable from "@loadable/component";
import { Circles } from "react-loader-spinner";

import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AlertPopup from "../components/common/AlertPopup/";

import Header from "../components/common/header";

import Contact from "../frontend/contact";
import About from "../frontend/about";
import NotFound from "../frontend/notFound";

//ADMIN
const Loading = (props) => {
  if (props.error) {
    return (
      <div className="flex justify-center items-center p-5 mt-5">
        Error Occurred!
      </div>
    );
  } else {
    return (
      <div className="flex justify-center items-center p-5 mt-5">
        <div className="flex flex-col justify-start items-center">
          <Circles
            height="80"
            width="80"
            radius="48"
            color="#fff700"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
          <div className="text-[18px] text-green-800 mt-2">...Loading...</div>
        </div>
      </div>
    );
  }
};

const Home = loadable(
  () => import("../frontend/home" /* webpackChunkName: "home" */),
  {
    fallback: <Loading />,
  }
);

const Cal = loadable(
  () => import("../frontend/Cal" /* webpackChunkName: "Calendar" */),
  {
    fallback: <Loading />,
  }
);

const FindZakar = loadable(
  () => import("../frontend/findZakar" /* webpackChunkName: "Find Zakar" */),
  {
    fallback: <Loading />,
  }
);
const Festival = loadable(
  () => import("../frontend/festival" /* webpackChunkName: "Festival" */),
  {
    fallback: <Loading />,
  }
);

/* test only */
const MembersTst = loadable(
  () => import("../frontend/members" /* webpackChunkName: "login" */),
  {
    fallback: <Loading />,
  }
);

const Login = loadable(
  () => import("../frontend/login" /* webpackChunkName: "login" */),
  {
    fallback: <Loading />,
  }
);

const Resetpassword = loadable(
  () =>
    import("../frontend/resetpassword" /* webpackChunkName: "resetpassword" */),
  {
    fallback: <Loading />,
  }
);

const ForceResetpassword = loadable(
  () =>
    import(
      "../frontend/forcepasswordreset" /* webpackChunkName: "forceresetpassword" */
    ),
  {
    fallback: <Loading />,
  }
);
const Forgotpassword = loadable(
  () =>
    import(
      "../frontend/forgotpassword" /* webpackChunkName: "forgotpassword" */
    ),
  {
    fallback: <Loading />,
  }
);
const PageView = loadable(
  () => import("../frontend/page" /* webpackChunkName: "fontend/page" */),
  {
    fallback: <Loading />,
  }
);

const PageImgView = loadable(
  () =>
    import(
      "../frontend/page/pageImgView" /* webpackChunkName: "fontend/page" */
    ),
  {
    fallback: <Loading />,
  }
);
//ADMIN
const Dashboard = loadable(
  () => import("../admin/dashboard" /* webpackChunkName: "dashboard" */),
  {
    fallback: <Loading />,
  }
);
const ZakarList = loadable(
  () => import("../admin/zakar" /* webpackChunkName: "zakar" */),
  {
    fallback: <Loading />,
  }
);

const ZakarMgt = loadable(
  () =>
    import("../admin/zakar/manage" /* webpackChunkName: "zakar management" */),
  {
    fallback: <Loading />,
  }
);

const Members = loadable(
  () => import("../admin/members" /* webpackChunkName: "members" */),
  {
    fallback: <Loading />,
  }
);

const MemberDetail = loadable(
  () =>
    import("../admin/members/MemberDetail" /* webpackChunkName: "members" */),
  {
    fallback: <Loading />,
  }
);

const PushNotification = loadable(
  () =>
    import(
      "../admin/pushnotification" /* webpackChunkName: "pushnotification" */
    ),
  {
    fallback: <Loading />,
  }
);
const SettingsList = loadable(
  () => import("../admin/settings" /* webpackChunkName: "settingslist" */),
  {
    fallback: <Loading />,
  }
);

const CompanySettings = loadable(
  () => import("../admin/company" /* webpackChunkName: "companysettings" */),
  {
    fallback: <Loading />,
  }
);

const Menu = loadable(
  () => import("../admin/menu" /* webpackChunkName: "menu" */),
  {
    fallback: <Loading />,
  }
);

const Profile = loadable(
  () => import("../admin/profile" /* webpackChunkName: "profile" */),
  {
    fallback: <Loading />,
  }
);
const NotAuthorized = loadable(
  () =>
    import("../admin/notauthorized" /* webpackChunkName: "notauthorized" */),
  {
    fallback: <Loading />,
  }
);
const PageList = loadable(
  () => import("../admin/pages" /* webpackChunkName: "pages" */),
  {
    fallback: <Loading />,
  }
);

const AdList = loadable(
  () => import("../admin/ad" /* webpackChunkName: "pages" */),
  {
    fallback: <Loading />,
  }
);

const InAppMessage = loadable(
  () => import("../admin/inAppMessage" /* webpackChunkName: "InAppMessage" */),
  {
    fallback: <Loading />,
  }
);

const FestivalMgt = loadable(
  () =>
    import("../admin/festivalMgt" /* webpackChunkName: "Admin festival Mgt" */),
  {
    fallback: <Loading />,
  }
);

const FestivalMultiEntry = loadable(
  () =>
    import(
      "../admin/festivalMgt/MultiEntry" /* webpackChunkName: "Festival" */
    ),
  {
    fallback: <Loading />,
  }
);

const CreateFestivalDates = loadable(
  () =>
    import(
      "../admin/festivalMgt/CreateFestivalDates" /* webpackChunkName: "Festival" */
    ),
  {
    fallback: <Loading />,
  }
);

// const ImportCSV = loadable(
//   () =>
//     import("../admin/festival/ImportCSV" /* webpackChunkName: "ImportCSV" */),
//   {
//     fallback: <Loading />,
//   }
// );

const ImportExport = loadable(
  () =>
    import(
      "../admin/zakar/ImportExport" /* webpackChunkName: "Import export" */
    ),
  {
    fallback: <Loading />,
  }
);

const Router = ({ props }) => {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <AlertPopup key={2} />

          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/cal" element={<Cal />} />
            <Route exact path="/find-zakar" element={<FindZakar />} />

            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot" element={<Forgotpassword />} />
            <Route exact path="/festival" element={<Festival />} />

            <Route
              exact
              path="/forcereset/:loggedInEmail/:loggedInPassword/:loggedInName"
              element={<ForceResetpassword />}
            />

            <Route exact path="/reset/:vc/:email" element={<Resetpassword />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/mem" element={<MembersTst />} />

            {/* <Route path="/notify" element={<Notify />} />


              */}

            <Route exact path="/page/:pageTitle" element={<PageView />} />
            <Route exact path="/pimg/:pageTitle" element={<PageImgView />} />

            {/* ADMIN ROUTE */}
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/ad" element={<AdList />} />
            <Route path="/admin/appmsg" element={<InAppMessage />} />

            <Route path="/admin/pages" element={<PageList />} />

            <Route path="/admin/festival" element={<FestivalMgt />} />
            <Route
              path="/admin/festival/multi"
              element={<FestivalMultiEntry />}
            />
            <Route
              path="/admin/festival/create"
              element={<CreateFestivalDates />}
            />

            {/*
            <Route path="/admin/festival/csv" element={<ImportCSV />} /> */}
            <Route path="/admin/zakar" element={<ZakarList />} />
            <Route path="/admin/zakar/mgt" element={<ZakarMgt />} />

            <Route path="/admin/settings" element={<SettingsList />} />
            <Route path="/admin/company" element={<CompanySettings />} />
            <Route path="/admin/menu" element={<Menu />} />

            <Route
              path="/admin/pushnotification"
              element={<PushNotification />}
            />

            <Route path="/admin/zakar/data" element={<ImportExport />} />
            <Route path="/admin/members" element={<Members />} />
            <Route path="/admin/members/:memberId" element={<MemberDetail />} />

            <Route path="/admin/profile" element={<Profile />} />

            <Route path="/notauthorized" element={<NotAuthorized />} />
            <Route exact path="/error" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </>
    </HelmetProvider>
  );
};

export default Router;
