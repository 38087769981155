import { AES, enc } from "crypto-js";

// const secretKey = process.env.REACT_APP_SECRET_KEY;
const secretKey = "Karma321";

export function encryptData(data) {
  if (data && data.length > 0) {
    const encryptedData = AES.encrypt(data, secretKey).toString();
    return encryptedData;
  }
}
export function decryptData(edata) {
  if (edata && edata.length > 0) {
    const decryptedData = AES.decrypt(edata, secretKey).toString(enc.Utf8);
    return decryptedData;
  }
}
