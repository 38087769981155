import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeMessageBar } from "../../../store/TopbarMsg";

export const TopbarMsg = () => {
  const dispatch = useDispatch();
  const [bg, setBg] = useState("");
  const [textColor, setTextColor] = useState("");
  const alert = useSelector((state) => state.reducer.topBarMsg);
  const close = () => {
    dispatch(closeMessageBar());
  };
  useEffect(() => {
    if (alert.messageType === "success") {
      setBg("lime-500");
      setTextColor("white");
    } else if (
      alert.messageType === "danger" ||
      alert.messageType === "error"
    ) {
      setBg("red-500");
      setTextColor("gray-100");
    } else if (alert.messageType === "info") {
      setBg("sky-500");
      setTextColor("black");
    } else {
      setBg("orange-500");
      setTextColor("black");
    }
  }, [alert]);

  useEffect(() => {
    setTimeout(() => {
      close();
    }, 30000);
  }, [alert.show]);

  if (!alert.show) return;

  return (
    <>
      <div className="bg-sky-500 bg-lime-500 bg-sky-500 bg-black bg-red-500 bg-yellow-500 bg-slate-900 text-gray-100 hidden" />

      <div
        className={`w-screen p-3 text-${textColor} sticky z-[10001] top-0 right-0 left-0 w-full flex justify-center items-center bg-${bg}`}
      >
        <div
          className="flex justify-center items-center absolute top-0 right-[15px] w-[20px] h-[20px] rounded-full bg-gray-500/70 text-white text-[12px] cursor-pointer"
          onClick={close}
        >
          X
        </div>
        <div className="flex flex-col justify-center items-center">
          <div
            className="font-bold"
            dangerouslySetInnerHTML={{ __html: alert.title }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: alert.message }}></div>
        </div>
      </div>
    </>
  );
};

export default TopbarMsg;
