import React, { useEffect } from "react";
import MainNavbar from "./MainNavbar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanySuccess } from "../../../store/Company";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { userLogOut } from "../../../store/User";

const baseUrl = window.siteSettings.baseUrl;

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const loggedIn = useSelector((state) => state.reducer.CurrentUser.loggedIn);

  const loginExpire = useSelector(
    (state) => state.reducer.CurrentUser.loginExpiresIn
  );

  const dispatch = useDispatch();

  async function runOnce() {
    if (!sessionStorage.getItem("InitialStart")) {
      sessionStorage.setItem("InitialStart", new Date());
      let body = { what: "all" };
      let url = baseUrl + "/api/company_get.php";

      const res = await axios({
        url: url,
        method: "POST",
        data: JSON.stringify(body),
        headers: { "content-type": "application/json" },
      });
      if (res.data.error) {
      } else {
        dispatch(fetchCompanySuccess(res.data));
      }
    }
  }

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      if (!loggedIn) {
        navigate("/login");
        console.log("admin site");
      }
    } else {
      //check if login is not expired
      var today = new Date();
      try {
        if (moment(loginExpire).diff(today, "days") < 1) {
          dispatch(userLogOut());
          navigate("/login");
        }
      } catch (err) {
        dispatch(userLogOut());
        navigate("/login");
      }
    }
  }, []);
  useEffect(() => {
    runOnce();
  }, []);
  return (
    <div>
      <MainNavbar />
    </div>
  );
}
