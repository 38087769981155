import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// import { RxTriangleDown, RxTriangleUp } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

import { motion, AnimatePresence } from "framer-motion";
import { RiLogoutCircleFill } from "react-icons/ri";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { IoMdHome } from "react-icons/io";
import { FaBinoculars } from "react-icons/fa";

// import { AdminNavItems } from "./AdminNavItems";
// import { RegularNavItems } from "./RegularNavItems";

import { decryptData } from "../../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { userLogOut } from "../../../store/User";

import logo from "../../../assets/logo.png";
import axios from "axios";
import DynamicIcon from "../icons";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaGlobe } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";

import DropdownMobileMenu from "../dropdownMobileMenu";
import IsOutSideClick from "../../OutsideClick";
import HorizontalMenu from "./HorizontalMenu";
const baseUrl = window.siteSettings.baseUrl;

function MainNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [adminNavList, setAdminNavList] = useState([]);
  const [navList, setNavList] = useState([]);

  const user = useSelector((state) => state.reducer.CurrentUser.currentUser);
  const userRole = useSelector((state) => state.reducer.CurrentUser.role);
  const loggedIn = useSelector((state) => state.reducer.CurrentUser.loggedIn);
  const company = useSelector((state) => state.reducer.companySettings.list);
  const [showMenu, setShowMenu] = useState(false);
  const [navBarItems, setNavBarItems] = useState([]);
  const [allMenuItems, setAllMenuItems] = useState([]);
  const [isAdminPath, setIsAdminPath] = useState(false);
  const [pageList, setPageList] = useState([]);
  const [showPageList, setShowPageList] = useState(false);

  const [role, setRole] = useState("user");
  const [logoUrl, setLogoUrl] = useState(logo);

  const menuRef = useRef();

  const logout = () => {
    dispatch(userLogOut());
    window.location.replace("/login");
  };

  const getNavItems = async () => {
    const body = {
      what: "all",
    };
    try {
      let url = baseUrl + "/api/menu_get.php";
      const res = await axios({
        url: url,
        method: "POST",
        data: JSON.stringify(body),
        headers: { "content-type": "application/json" },
      });
      if (res.data.error !== null) {
        if (res.data.error === "true") {
          console.log("Error", res.data.message);
        } else {
          setAllMenuItems(res.data);
          const al = res.data.filter(
            (fd) =>
              fd.menu_type.split(",").includes("admin") &&
              parseInt(fd.parent_id) === 0
          );

          const ul = res.data.filter(
            (fd) =>
              fd.menu_type.split(",").includes("regular") &&
              parseInt(fd.parent_id) === 0
          );
          setAdminNavList(al);
          setNavList(ul);
        }
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  useEffect(() => {
    getNavItems();

    if (company && company.length > 0) {
      if (company[0].logo_file_name) {
        setLogoUrl(`${baseUrl}/uploads/company/${company[0].logo_file_name}`);
      }
    } else {
      setLogoUrl(logo);
    }
  }, [company]);

  async function getPages() {
    const body = {
      what: "all",
    };
    try {
      let url = baseUrl + "/api/pages_get.php";

      const res = await axios({
        url: url,
        method: "POST",
        data: JSON.stringify(body),
        headers: { "content-type": "application/json" },
      });
      setPageList(res.data);
    } catch (error) {
      console.log("Error getting pages", error);
    }
  }
  useEffect(() => {
    getPages();
  }, []);
  useEffect(() => {
    //check path and set nav items.
    if (location.pathname.includes("admin")) {
      if (loggedIn && decryptData(userRole) === "admin") {
        setIsAdminPath(true);
        setNavBarItems(adminNavList);
        setRole(decryptData(userRole));
        setShowPageList(false);
      } else {
        setNavBarItems(navList);
        setRole(decryptData(userRole));
      }
    } else {
      setIsAdminPath(false);
      setNavBarItems(navList);
      setShowPageList(true);
      // setRole(decryptData(userRole));
    }

    // if (loggedIn && decryptData(userRole) === "admin") {
    //   setNavBarItems(adminNavList);
    //   setRole(decryptData(userRole));
    // } else {
    //   setNavBarItems(navList);
    //   // setRole(decryptData(userRole));
    // }
  }, [loggedIn, adminNavList, navList, userRole, pageList, location.pathname]);

  const getUserInfo = () => {
    // let userImage = null;
    if (loggedIn && user) {
      if (user.photo_filename && user.photo_filename.length > 0) {
        return (
          <div className="flex flex-col items-center  w-[38px] h-[38px] rounded-full bg-orage-500 justify-center">
            <div>
              <img
                alt="member"
                src={`${baseUrl}/uploads/members/${decryptData(
                  user.photo_filename
                )}`}
                className="w-[60px] h-[60px]  border-0 rounded-full md:w-[35px] md:h-[35px]"
              />
            </div>
            {/* <div>
              <span>
                {decryptData(user.firstname).substring(0, 1) +
                  decryptData(user.lastname).substring(0, 1)}
              </span>
            </div> */}
          </div>
        );
      } else {
        return (
          <div className=" flex flex-row justify-center items-center w-[35px] h-[35px] rounded-full bg-orange-400 text-black">
            {decryptData(user.firstname).substring(0, 1) +
              decryptData(user.lastname).substring(0, 1)}
          </div>
        );
      }
    }

    // if (userImage.length < 1) {
    //   return (
    //     <div className="profileImgCircle">
    //       {showUserInfoPopup ? (
    //         <RxTriangleUp className="profileImgArrow" />
    //       ) : (
    //         <RxTriangleDown className="profileImgArrow" />
    //       )}

    //       {userImage}
    //     </div>
    //   );
    // } else {
    //   return "";
    // }
  };

  const getMobileUserInfo = () => {
    if (!loggedIn) {
      return (
        <div className="flex justify-end items-center gap-2 mr-2">
          <div className="d-flex justify-content-end w-100">
            <div
              className="btn-group"
              role="group"
              aria-label="login and register button"
            >
              <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={() => {
                  navigate("/login");
                  setShowMenu(false);
                }}
              >
                <div className="flex items-center text-black">
                  <DynamicIcon name={"AiOutlineLogin"} />
                  <div className="pl-[3px]">Login</div>
                </div>
              </button>
              {/* <button type="button" className="btn btn-sm bg-babyblue">
                Signup
              </button> */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-end items-center gap-2 mr-5">
          <div
            className=" small text-white cursor-pointer"
            onClick={() => navigate("/admin/profile")}
          >
            <div className="">{getUserInfo()}</div>
          </div>
          {/* <div>
            <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={() => {
                logout();
                setShowMenu(false);
              }}
            >
              Logout
            </button>
          </div> */}
        </div>
      );
    }
  };

  function hasSubMenu(menuId) {
    const sm = allMenuItems.filter(
      (m) => parseInt(m.parent_id) === parseInt(menuId)
    );
    return sm;
  }
  IsOutSideClick(() => setShowMenu(false), menuRef);

  return (
    <>
      <div>
        <div className="container relative pt-2">
          <div className="sticky top-0">
            <div className="absolute top-0 right-0 md:right-5 flex gap-1 md:gap-2 items-center pt-2">
              {!isAdminPath && (
                <>
                  <div
                    title="Home"
                    onClick={() => navigate("/")}
                    className="pointer-cursor bg-orange w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer hover:opacity-50"
                  >
                    <IoMdHome color="white" size={20} />
                  </div>
                  <div
                    onClick={() => navigate("/find-zakar")}
                    title="Find Zakar"
                    className="pointer-cursor bg-orange w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer hover:opacity-50"
                  >
                    <FaBinoculars color="white" size={20} />
                  </div>
                </>
              )}

              {isAdminPath && (
                <a href="/" className="no-underline" target="_blank">
                  <div
                    className=" bg-orange-600 flex justify-center items-center rounded-2xl px-2 py-[3px] cursor-pointer gap-2 hover:opacity-50"
                    title="drukzakar.com"
                  >
                    <FaGlobe size={20} color="white" />
                    <div className="text-white text-sm">Main Site</div>
                  </div>
                </a>
              )}
              {loggedIn && !isAdminPath && (
                <>
                  <a className="no-underline" href="/admin/" target="_blank">
                    <div
                      title="Administration Site"
                      className=" bg-orange-600 hover:bg-orange-500/50 flex justify-center items-center rounded-2xl px-2 py-[3px] cursor-pointer hover:opacity-50"
                    >
                      <RiAdminFill size={20} color="white" />
                      <div className="text-white text-sm">Admin</div>
                    </div>
                  </a>
                </>
              )}

              <div className=" bg-orange-600 hover:bg-orange-500/50 flex justify-center items-center rounded-2xl px-2 py-[3px] cursor-pointer  hover:opacity-50">
                {loggedIn ? (
                  <button
                    className="flex gap-1 justify-center item-center "
                    onClick={logout}
                  >
                    <div className="text-white text-sm " title="Logout">
                      Logout
                    </div>
                    <RiLogoutCircleRFill size={20} color="white" />
                  </button>
                ) : (
                  <button
                    className="flex gap-2 justify-center item-center"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <RiLogoutCircleFill size={20} color="white" />
                    <div
                      className="text-white text-sm "
                      title="Only For Administration"
                    >
                      Login
                    </div>
                  </button>
                )}
              </div>

              {showMenu && (
                <div
                  title="Menu"
                  className="text-black/50 hover:text-black hover:bg-orange-500/50 cursor-pointer  bg-orange-600 h-9 w-9 rounded-full z-50  flex justify-center items-center  shadow"
                >
                  <button onClick={() => setShowMenu(false)}>
                    <GiHamburgerMenu size={22} />
                  </button>
                </div>
              )}

              {!showMenu && (
                <div className="text-black/50 hover:text-black hover:bg-orange-500/50 cursor-pointer  bg-orange-500 h-9 w-9 rounded-full z-50  flex justify-center items-center  shadow">
                  <button onClick={() => setShowMenu(true)}>
                    <GiHamburgerMenu size={22} />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex p-0 m-0">
            <div
              className="flex flex-col justify-center items-center p-0 m-0 cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={logo}
                className="w-[80px] md:w-[120px]"
                alt="druk zakar"
              />
              <div className="text-black/80 font-extrabold mt-3 text-[17px] md:text-[23px] text-yellow-300 shadow-sm">
                DRUK ZAKAR
              </div>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {/* drawer nav menu */}
          {showMenu && (
            <div className="absolute left-0 top-0 h-full w-[75%] md:w-[40%]  z-40 pt-3">
              <motion.div
                ref={menuRef}
                animate={{ width: showMenu ? "100%" : 0 }}
                exit={{ width: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="absolute left-0 top-0 h-full w-[75%] md:w-[40%] bg-yellow-600/95  z-40 pt-3"
              >
                <div
                  className=" absolute top-1 right-1 h-5 w-5 bg-black rounded-full flex justify-center items-center cursor-pointer"
                  onClick={() => setShowMenu(false)}
                >
                  <AiOutlineClose color="orange" size={13} />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className=" h-[130px] w-[130px] p-0  mb-2 rounded-full flex justify-center items-center">
                    <motion.img
                      animate={{ opacity: showMenu ? 1 : 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5, ease: "easeInOut" }}
                      src={logoUrl}
                      className=""
                      alt="druk zakar logo"
                    />
                  </div>

                  <div className="font-bold">DRUK ZAKAR</div>
                </div>

                {getMobileUserInfo()}
                <div className="flex flex-col pl-4 mt-5">
                  {navBarItems.map((m, index) => {
                    return (
                      <div key={index} className="pb-3">
                        {m.access_role.includes(role) ? (
                          hasSubMenu(m.id) && hasSubMenu(m.id).length > 0 ? (
                            <div className="relative">
                              <div className="flex items-center">
                                <DropdownMobileMenu
                                  dropdownLbl={m.title}
                                  iconName={m.icon}
                                >
                                  {hasSubMenu(m.id).map((sm, sidx) => (
                                    <div
                                      key={sidx}
                                      className=" py-1 px-2 hover:bg-yellow-400 flex item-center"
                                    >
                                      <Link
                                        className=" no-underline "
                                        onClick={() => setShowMenu(false)}
                                        to={sm.link}
                                        key={sidx}
                                      >
                                        <div className="flex items-center">
                                          <DynamicIcon
                                            name={sm.icon}
                                            clName="text-black mr-3"
                                          />
                                          <div className="text-black">
                                            {sm.title}
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </DropdownMobileMenu>
                              </div>
                            </div>
                          ) : (
                            <div
                              key={index}
                              onClick={() => setShowMenu(false)}

                              // className={router.pathname === m.link ? 'active' : ''}
                            >
                              <Link
                                className=" no-underline"
                                to={m.link}
                                key={index}
                                onClick={() => setShowMenu(false)}
                              >
                                <div className="flex items-center ">
                                  <DynamicIcon
                                    name={m.icon}
                                    clName="text-black mr-3"
                                  />
                                  <div className="text-black">{m.title}</div>
                                </div>
                              </Link>
                            </div>
                          )
                        ) : null}
                      </div>
                    );
                  })}
                  {showPageList &&
                    pageList.map((p, index) => {
                      return (
                        <div key={index} onClick={() => setShowMenu(false)}>
                          <Link
                            className=" no-underline"
                            to={"/page/" + p.eng_title}
                            key={index}
                            onClick={() => setShowMenu(false)}
                          >
                            <div className="flex items-center ">
                              <DynamicIcon
                                name={p.web_icon_name}
                                clName="text-black mr-3"
                              />
                              <div className="flex flex-col gap-1 pt-2 pb-1">
                                <div className="text-black">{p.eng_title}</div>
                                <div className="text-black">{p.dz_title}</div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
      <HorizontalMenu />
    </>
  );
}

export default MainNavbar;
