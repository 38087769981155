import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { RxTriangleDown, RxTriangleUp } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

import { motion, AnimatePresence } from "framer-motion";
import { RiLogoutCircleFill } from "react-icons/ri";
import { RiLogoutCircleRFill } from "react-icons/ri";

// import { AdminNavItems } from "./AdminNavItems";
// import { RegularNavItems } from "./RegularNavItems";

import { decryptData } from "../../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { userLogOut } from "../../../store/User";

import logo from "../../../assets/logo.png";
import axios from "axios";
import DynamicIcon from "../icons";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaGlobe } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";

import DropdownMobileMenu from "../dropdownMobileMenu";
import IsOutSideClick from "../../OutsideClick";
const baseUrl = window.siteSettings.baseUrl;

function MainNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [adminNavList, setAdminNavList] = useState([]);
  const [navList, setNavList] = useState([]);

  const user = useSelector((state) => state.reducer.CurrentUser.currentUser);
  const userRole = useSelector((state) => state.reducer.CurrentUser.role);
  const loggedIn = useSelector((state) => state.reducer.CurrentUser.loggedIn);
  const company = useSelector((state) => state.reducer.companySettings.list);
  const [showMenu, setShowMenu] = useState(false);
  const [navBarItems, setNavBarItems] = useState([]);
  const [allMenuItems, setAllMenuItems] = useState([]);
  const [isAdminPath, setIsAdminPath] = useState(false);
  const [pageList, setPageList] = useState([]);
  const [showPageList, setShowPageList] = useState(false);

  const [role, setRole] = useState("user");
  const [logoUrl, setLogoUrl] = useState(logo);

  const getNavItems = async () => {
    const body = {
      what: "all",
    };
    try {
      let url = baseUrl + "/api/menu_get.php";
      const res = await axios({
        url: url,
        method: "POST",
        data: JSON.stringify(body),
        headers: { "content-type": "application/json" },
      });
      if (res.data.error !== null) {
        if (res.data.error === "true") {
          console.log("Error", res.data.message);
        } else {
          setAllMenuItems(res.data);
          const al = res.data.filter(
            (fd) =>
              fd.menu_type.split(",").includes("admin") &&
              parseInt(fd.parent_id) === 0
          );

          const ul = res.data.filter(
            (fd) =>
              fd.menu_type.split(",").includes("regular") &&
              parseInt(fd.parent_id) === 0
          );
          setAdminNavList(al);
          setNavList(ul);
        }
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  useEffect(() => {
    getNavItems();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      if (loggedIn && decryptData(userRole) === "admin") {
        setIsAdminPath(true);
        setNavBarItems(adminNavList);
        setRole(decryptData(userRole));
        setShowPageList(false);
      } else {
        setNavBarItems(navList);
        setRole(decryptData(userRole));
      }
    } else {
      setIsAdminPath(false);
      setNavBarItems(navList);
      setShowPageList(true);
      // setRole(decryptData(userRole));
    }
  }, [loggedIn, adminNavList, navList, userRole, pageList]);

  function hasSubMenu(menuId) {
    const sm = allMenuItems.filter(
      (m) => parseInt(m.parent_id) === parseInt(menuId)
    );
    return sm;
  }
  if (!isAdminPath) return;
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="md:flex flex-row pl-4 mt-5 gap-4 hidden justify-center items-center bg-orange-400 px-3 py-2 rounded-3xl">
          {navBarItems.map((m, index) => {
            return (
              <div key={index}>
                {m.access_role.includes(role) ? (
                  hasSubMenu(m.id) && hasSubMenu(m.id).length > 0 ? (
                    <div className="relative">
                      <div className="flex items-center">
                        <DropdownMobileMenu
                          dropdownLbl={m.title}
                          iconName={m.icon}
                        >
                          {hasSubMenu(m.id).map((sm, sidx) => (
                            <div
                              key={sidx}
                              className="py-1 px-2 hover:bg-yellow-400 flex item-center"
                            >
                              <Link
                                className="no-underline"
                                onClick={() => setShowMenu(false)}
                                to={sm.link}
                                key={sidx}
                              >
                                <div className="flex items-center">
                                  <DynamicIcon
                                    name={sm.icon}
                                    clName="text-black mr-1"
                                  />
                                  <div className="text-black">{sm.title}</div>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </DropdownMobileMenu>
                      </div>
                    </div>
                  ) : (
                    <div key={index} onClick={() => setShowMenu(false)}>
                      <Link
                        className="no-underline"
                        to={m.link}
                        key={index}
                        onClick={() => setShowMenu(false)}
                      >
                        <div className="flex items-center ">
                          <DynamicIcon
                            name={m.icon}
                            clName="text-black  mr-1"
                          />
                          <div className="text-black">{m.title}</div>
                        </div>
                      </Link>
                    </div>
                  )
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default MainNavbar;
