import React from "react";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import TopbarMsg from "./components/common/TopbarMsg";
function App() {
  return (
    <>
      <TopbarMsg />
      <div className="bg-gradient-to-b from-orange-500 to-orange-700 px-2 pb-2">
        <BrowserRouter>
          <div className="container">
            {/* <Header /> */}
            <Router />
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
