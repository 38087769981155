import React, { Children, useEffect, useState } from "react";
import * as BS from "react-icons/bs";
import DynamicIcon from "../icons";

import { CSSTransition } from "react-transition-group";

export default function DropdownMobileMenu({
  bgColor = "transparent",
  dropdownLbl,
  children,
  titleTextColor = "black",
  iconName,
}) {
  const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setShowSubMenu(!showSubMenu)}
        onClick={() => setShowSubMenu(!showSubMenu)}
        onMouseLeave={() => setShowSubMenu(false)}
        className="relative"
      >
        <div className="cursor-pointer flex items-center">
          <div className="flex justify-between items-center">
            <div className="mr-1">
              <DynamicIcon name={iconName} classname="text-black" />
            </div>
            <div className=" mr-2">{dropdownLbl}</div>
            <div className="text-black">
              <BS.BsChevronDown className={showSubMenu ? "rotate-180" : ""} />
            </div>
          </div>
        </div>
        {showSubMenu && (
          <div
            className=" bg-yellow-500 ml-5 pt-2 absolute min-w-[190px]"
            style={{ zIndex: 1001 }}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
}
